import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "./slider.css";

function Slider(props) {
  const [activeButton, setActiveButton] = useState(
    localStorage.getItem("lng") || "es"
  );

  const renderSlider = () => {
      return (
        <div className="slideBorder">
          <div className="carouselContainer">
            <Carousel interval={5000} controls={true} indicators={true}>
              {props.banners
                .sort((a, b) => a.posicion - b.posicion) // Ordenar por la propiedad 'posicion'
                .map((banner, index) => (
                  (banner.landing === "1" || banner.landing === "3") && ( // Validar que el landing sea 1 o 3
                    <Carousel.Item key={banner.id}>
                      {banner.link !== "" || banner.link_en !== "" ? (
                        <a href={activeButton === "es" ? banner.link : banner.link_en} id={`bannerHome${index}`}>
                          <img
                            src={
                              activeButton === "es" ? banner.image : banner.image_en
                            }
                            alt={banner.image}
                          />
                        </a>
                      ) : (
                        <img
                          src={
                            activeButton === "es" ? banner.image : banner.image_en
                          }
                          alt={banner.image}
                        />
                      )}
                    </Carousel.Item>
                  )
                ))}
            </Carousel>
          </div>
        </div>
      );
    };


  return <>{renderSlider()}</>;
}

export default Slider;
